<template>
   <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="my-profile-second">
      <div class="profile-card">
        <div class="profile-avatar">
          <div class="profile-avatar_img">
            <img src="@/assets/img/paw-logo.png" alt="">

          </div>
          <div class="profile-avatar_check">
            <img src="@/assets/img/checked.png" alt="" />
          </div>
        </div>
        <div class="profile-info">
          <p class="profile-name">{{ user.name }}</p>
          <!-- <p class="profile-phone">0123456789</p> -->
        </div>

        <div class="profile-button">
          <div class="profile-card_btn btn-left">
            <i class="ti ti-qrcode"></i>
            <p>Trang cá nhân</p>
            <i class="ti ti-chevron-right"></i>
          </div>
          <router-link class="profile-card_btn btn-right" to="/invite">
            <i class="ti ti-share"></i>
            <p>Giới thiệu CAT</p>
            <i class="ti ti-chevron-right"></i>
          </router-link>
        </div>
      </div>
    </section>

    <section class="profile-action">
      <p class="profile-action-title">Thiết lập cá nhân</p>
      <div class="profile-action-main">
        <router-link to="/edit-pw">
          <div class="d-flex justify-content-between align-items-center cursor-pointer">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-lock profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">Thông tin đăng nhập</p>
                <p class="profile-action_items-subtext">
                  Đổi mật khẩu
                </p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </router-link>

        <a href="">
          <div class="d-flex justify-content-between align-items-center cursor-pointer">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-report-money profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">Thông tin rút tiền</p>
                <p class="profile-action_items-subtext">
                  Thay đổi thông tin rút tiền
                </p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </a>
        <router-link to="/binkbank">
          <div class="d-flex justify-content-between align-items-center cursor-pointer">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-credit-card profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">Tài khoản USDT</p>
                <p class="profile-action_items-subtext">
                  Thay đổi thông tin tài khoản thanh toán
                </p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </router-link>
      </div>
    </section>

    <section class="profile-action-support">
      <p class="profile-action-title">Hỗ trợ</p>
      <div class="profile-action-main">
        <a href="">
          <div class="d-flex justify-content-between align-items-center cursor-pointer">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-gavel profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">
                  Điều khoản và điều kiện
                </p>
                <p class="profile-action_items-subtext">
                  Điều khoản và điều kiện
                </p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </a>

        <a href="">
          <div class="d-flex justify-content-between align-items-center cursor-pointer">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-message-circle-question profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">Câu hỏi thường gặp</p>
                <p class="profile-action_items-subtext">Câu hỏi thường gặp</p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </a>

        <a href="">
          <div class="d-flex justify-content-between align-items-center cursor-pointer">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-file-info profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">Về chúng tôi</p>
                <p class="profile-action_items-subtext">Về chúng tôi</p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </a>

        <a href="https://livechat123.vip/chat/moneycat@gmail.com587813868108587">
          <div class="d-flex justify-content-between align-items-center cursor-pointer ">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-headset profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">Chăm sóc khách hàng</p>
                <p class="profile-action_items-subtext">Tổng đài hỗ trợ 24/7</p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </a>
      </div>
    </section>

    <section class="loggout" @click="logout">
      <div class="profile-action-main loggout-main">
        <div class="d-flex justify-content-center align-items-center">
          <div class="d-flex align-items-center gap-2">
            <i class="ti ti-logout profile-action_items-icon loggout-icon"></i>
            <div class="d-flex flex-column">
              <p class="profile-action_items-text loggout-text" >Đăng xuất</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network'

export default {
    components: {Footer},
    data() {
      return {
        user: {}
      }
    },
    mounted() {
      this.getProfile()
    },
    methods: {
      logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        this.$toast.open({
                        message: 'Phiên đăng nhập hết hạn',
                        type: 'error',
                        position: 'top',
                        duration:3000,
                      });     
        this.$router.push('/auth/login');

      },
      getProfile() {
        network.get('/users/profile').then( res => {
          console.log(res.data.data);
          this.user = res.data.data;
        }).catch(err => {
          console.log(err);
        })
      },
    },


}
</script>

<style>

</style>